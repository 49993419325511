<template>
  <div class="mainBody">
    <div class="card">
      <div
        style="
          border-radius: 200px;
          height: 200px;
          width: 200px;
          background: #f8faf5;
          margin: 0 auto;
        "
      >
        <i class="checkmark">✓</i>
      </div>
      <h1 class="sheading">Success</h1>
      <p class="smessagecls">
        Payment was sccessfull;<br />
        Check the profile for details! <br />
        <a href="/MyAccount"> Go to profile</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      successStatus: true,
    };
  },
  mounted() {
    window.scrollTo(0, 0);

    setTimeout(() => {
      this.gotoPreviousPage();
    }, 3000);
  },
  methods: {
    gotoPreviousPage() {
      const returnUrl = sessionStorage.getItem("returnUrl");
      if (returnUrl) {
        window.location = returnUrl;
      }
    },
  },
};
</script>

<style>
.sheading {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.smessagecls {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.checkmark {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.mainBody {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
  margin-top: 80px;
}
.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>
